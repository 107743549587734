<template>
  <div class="article-details">
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <el-page-header
            style="
              width: 100%;
              height: 70px;
              display: flex;
              flex-direction: row;
              align-items: center;
            "
            @back="back"
            content="图文课程详情"
          >
          </el-page-header>

          <a-row>
            <a-col :span="24">
              <template v-if="empty">
                <a-row
                  type="flex"
                  justify="center"
                  style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
                >
                  <el-empty :description="description"></el-empty>
                </a-row>
              </template>
              <template v-else>
                <a-row
                  :gutter="24"
                  style="margin-top: 20px; min-height: 500px"
                  v-loading="loading"
                  element-loading-text="加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.2)"
                >
                  <div
                    class="article-content"
                    v-html="htmlFormat(articleClassDetails.htmlContent)"
                  ></div>
                  <div
                    v-if="articleClassDetails.htmlContent == ''"
                    style="font-weight: bold; font-size: 24px"
                  >
                    暂无图文内容
                  </div>
                </a-row>
              </template>
            </a-col>
          </a-row>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      loading: true,
      empty: false,
      articleClassDetails: {},
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  methods: {
    getArticleClassDetails() {
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: "/classroom/getSpecialSubjectArticleById",
          method: "post",
          data: {
            specialSubjectArticleId: this.$route.query.specialSubjectArticleId,
          },
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.code == 0 && res.data) {
            this.empty = false;
            this.articleClassDetails = { ...res.data };
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },
    back() {
      this.$router.back();
      this.$store.dispatch("set_num1", 1);
    },
  },
  created() {
    this.getArticleClassDetails();
  },
};
</script>

<style lang="scss" scoped>
.article-content {
  white-space: pre-line;
  text-align: left;
}
</style>
